import Vue from 'vue';
import Router from 'vue-router';

import {
  CHANGE_LOCATION,
  // FETCH_GEO_LOCATION,
  FETCH_USER_PROFILE,
  SHOW_MODAL
} from '@/store/action-types';

// Manually log nav errors (currently bugged on Firefox)
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject); }
  return originalPush.call(this, location).catch(err => console.error(err));
};

const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) { return originalReplace.call(this, location, onResolve, onReject); }
  return originalReplace.call(this, location).catch(err => console.error(err));
};

Vue.use(Router);

export default function createRouter({ store }) {
  const router = new Router({
    mode: 'history',
    routes: [
      {
        path: '/',
        name: 'home',
        component: () => import(/* webpackMode: "eager" */ '@/views/Index')
      },
      {
        path: '/shares/mine',
        name: 'my-shares',
        meta: { requireAuth: true },
        component: () => import(/* webpackMode: "eager" */ '@/views/MyShares')
      },
      {
        path: '/reset',
        name: 'password-reset',
        component: () => import(/* webpackMode: "eager" */ '@/views/PasswordReset')
      },
      {
        path: '/account/basic',
        name: 'manage-account',
        meta: { requireAuth: true },
        component: () => import(/* webpackMode: "eager" */ '@/views/ManageAccount')
      },
      {
        path: '/account/professional',
        name: 'manage-instructor',
        meta: { requireAuth: true },
        component: () => import(/* webpackMode: "eager" */ '@/views/ManageInstructor')
      },
      {
        path: '/account/instructor',
        redirect: { name: 'manage-instructor' }
      },
      {
        path: '/account/listings',
        name: 'manage-listings',
        meta: { requireAuth: true },
        component: () => import(/* webpackMode: "eager" */ '@/views/ManageListings')
      },
      {
        path: '/account/favorites',
        name: 'favorites',
        meta: { requireAuth: true },
        component: () => import(/* webpackMode: "eager" */ '@/views/Favorites')
      },
      {
        path: '/account/alerts',
        name: 'manage-favorite-airports',
        meta: { requireAuth: true },
        component: () => import(/* webpackMode: "eager" */ '@/views/ManageFavoriteAirports')
      },
      {
        path: '/account/subscription',
        name: 'manage-subscription',
        meta: { requireAuth: true },
        component: () => import(/* webpackMode: "eager" */ '@/views/ManageSubscription')
      },
      {
        path: '/find',
        name: 'find',
        component: () => import(/* webpackMode: "eager" */ '@/views/Find')
      },
      {
        path: '/list',
        name: 'post',
        meta: { requireAuth: true, requireListSub: true },
        component: () => import(/* webpackMode: "eager" */ '@/views/PostType')
      },
      {
        path: '/professionals',
        name: 'instructors',
        component: () => import(/* webpackMode: "eager" */ '@/views/Instructors')
      },
      {
        path: '/instructors',
        redirect: { name: 'instructors' }
      },
      {
        path: '/list/general',
        name: 'post-general',
        meta: { requireAuth: true, requireListSub: true },
        component: () => import(/* webpackMode: "eager" */ '@/views/PostGeneral')
      },
      {
        path: '/list/location',
        name: 'post-location',
        meta: { requireAuth: true, requireListSub: true },
        component: () => import(/* webpackMode: "eager" */ '@/views/PostLocation')
      },
      {
        path: '/list/media',
        name: 'post-media',
        meta: { requireAuth: true, requireListSub: true },
        component: () => import(/* webpackMode: "eager" */ '@/views/PostMedia')
      },
      {
        path: '/list/preferred-pro',
        name: 'post-preferred-instructor',
        meta: { requireAuth: true, requireListSub: true },
        component: () => import(/* webpackMode: "eager" */ '@/views/PostPreferredInstructor')
      },
      {
        path: '/list/preferred-instructor',
        redirect: { name: 'post-preferred-instructor' }
      },
      {
        path: '/list/confirm',
        name: 'post-confirm',
        meta: { requireAuth: true, requireListSub: true },
        component: () => import(/* webpackMode: "eager" */ '@/views/PostConfirm')
      },
      {
        path: '/faq',
        name: 'faq',
        component: () => import(/* webpackMode: "eager" */ '@/views/Help')
      },
      {
        path: '/company',
        name: 'company',
        component: () => import(/* webpackMode: "eager" */ '@/views/Company')
      },
      {
        path: '/our-story',
        name: 'our-story',
        component: () => import(/* webpackMode: "eager" */ '@/views/OurStory')
      },
      {
        path: '/our-team',
        name: 'our-team',
        component: () => import(/* webpackMode: "eager" */ '@/views/OurTeam')
      },
      {
        path: '/how-it-works',
        name: 'how-it-works',
        component: () => import(/* webpackMode: "eager" */ '@/views/HowItWorks')
      },
      {
        path: '/feedback',
        name: 'feedback',
        component: () => import(/* webpackMode: "eager" */ '@/views/Feedback')
      },
      {
        path: '/costcalc',
        name: 'costcalc',
        component: () => import(/* webpackMode: "eager" */ '@/views/CostCalc')
      },
      {
        path: '/earningscalc',
        name: 'earningscalc',
        component: () => import(/* webpackMode: "eager" */ '@/views/EarningsCalc')
      },
      {
        path: '/documents',
        name: 'sharing',
        component: () => import(/* webpackMode: "eager" */ '@/views/SharingDocuments')
      },
      {
        path: '/investors',
        name: 'investors',
        component: () => import(/* webpackMode: "eager" */ '@/views/Investors')
      },
      {
        path: '/seeking',
        name: 'seeking',
        component: () => import(/* webpackMode: "eager" */ '@/views/TipsForSeeking')
      },
      {
        path: '/listing',
        name: 'posting',
        component: () => import(/* webpackMode: "eager" */ '@/views/TipsForPosting')
      },
      {
        path: '/insurance',
        name: 'insurance',
        component: () => import(/* webpackMode: "eager" */ '@/views/Insurance')
      },
      {
        path: '/announcements',
        name: 'announcements',
        component: () => import(/* webpackMode: "eager" */ '@/views/Announcements')
      },
      {
        path: '/coflyt',
        name: 'coflyt',
        component: () => import(/* webpackMode: "eager" */ '@/views/Coflyt')
      },
      {
        path: '/cirrus',
        name: 'cirrus',
        component: () => import(/* webpackMode: "eager" */ '@/views/Cirrus')
      },
      {
        path: '/videos',
        name: 'videos',
        component: () => import(/* webpackMode: "eager" */ '@/views/Videos')
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: () => import(/* webpackMode: "eager" */ '@/views/Privacy')
      },
      {
        path: '/terms',
        name: 'terms',
        component: () => import(/* webpackMode: "eager" */ '@/views/Terms')
      },
      {
        path: '/signup',
        name: 'signup',
        component: () => import(/* webpackMode: "eager" */ '@/views/Signup')
      },
      {
        path: '/blog',
        name: 'blog',
        component: () => import(/* webpackMode: "eager" */ '@/views/Blog')
      },
      {
        path: '/blog/:slug',
        name: 'blog-post',
        component: () => import(/* webpackMode: "eager" */ '@/views/BlogPost')
      },
      {
        path: '/admin',
        name: 'admin',
        meta: { requireAdmin: true },
        component: () => import(/* webpackMode: "eager" */ '@/views/Admin')
      },
      {
        path: '/landing-frame',
        name: 'landing-frame',
        component: () => import(/* webpackMode: "eager" */ '@/views/LandingFrame')
      },
      {
        path: '/map-frame',
        name: 'map-frame',
        component: () => import(/* webpackMode: "eager" */ '@/views/MapFrame')
      },
      {
        path: '/auth/facebook',
        redirect: '/'
      },
      {
        path: '/auth/google',
        redirect: '/'
      },
      {
        path: '*',
        name: 'not-found',
        component: () => import(/* webpackMode: "eager" */ '@/views/NotFound')
      }
    ]
  });

  router.beforeEach(async (to, from, next) => {
  /*
  if (!store.getters.geoIsFetched) {
    try {
      await store.dispatch(FETCH_GEO_LOCATION);
    } catch (err) {
      console.error(err);
      return next(err);
    }
  }
  */

    // const redirect = from.name ? false : { name: 'home' };

    if (!store.getters.me && !from.name) {
      try {
        await store.dispatch(FETCH_USER_PROFILE);
      } catch (err) {
        console.error(err);
        await store.dispatch(SHOW_MODAL, { name: 'signup', afterSignupNav: to });
        // return next(redirect);
        return next({ name: 'home' });
      }
    }

    if (to.meta.requireAuth && !store.getters.me) {
      if (process.server) {
        return next({ name: 'home' });
      }

      await store.dispatch(SHOW_MODAL, { name: 'signup', afterSignupNav: to });
      // return next(redirect);
      return next(false);
    }

    if (to.meta.requireAdmin && !(store.getters.me && store.getters.me.isAdmin)) {
      if (process.server) {
        return next({ name: 'home' });
      }

      await store.dispatch(SHOW_MODAL, { name: 'signup', afterSignupNav: to });
      // return next(redirect);
      return next(false);
    }

    if (to.meta.requireListSub && !store.getters.me.subscriptions[process.env.LIST_SUBSCRIPTION_PRICE_ID].isActive) {
      if (process.server) {
        return next({ name: 'home' });
      }

      await store.dispatch(SHOW_MODAL, { name: 'subscribe-notification', subType: 'list' });
      return next(false);
    }

    if (from.name && to.name !== from.name) {
      await store.dispatch(CHANGE_LOCATION, { to, from });
      window.scrollTo(0, 0);
    }

    return next();
  });

  return router;
}
