<template>
  <div
    ref="header"
    class="header"
  >
    <router-link :to=" { name: 'home' }">
      <img
        src="@/assets/logo.jpeg"
        class="logo"
      >
    </router-link>
    <router-link :to=" { name: 'home' }">
      <img
        src="@/assets/sma_mobile_logo.png"
        class="mobile-logo"
      >
    </router-link>
    <div class="gap" />
    <div
      v-if="nav"
      v-click-outside="closeNav"
      class="links"
    >
      <router-link
        class="link"
        exact
        active-class="active"
        :to=" { name: 'find' }"
      >
        Find Planes
      </router-link>
      <router-link
        class="link has-drop"
        exact
        active-class="active"
        :to=" { name: 'instructors' }"
      >
        Find Professionals
        <div
          v-if="!(me && me.isInstructor)"
          class="drop"
        >
          <router-link
            class="link"
            exact
            active-class="active"
            :to="{ name: 'instructors' }"
            @click.native="instructorSignup"
          >
            Sign Up as a Professional
          </router-link>
        </div>
      </router-link>
      <router-link
        class="link"
        exact
        active-class="active"
        :to=" { name: 'post' }"
      >
        List Plane
      </router-link>
      <router-link
        class="link"
        exact
        active-class="active"
        :to=" { name: 'blog' }"
      >
        Blog
      </router-link>
      <router-link
        class="link has-drop"
        active-class="active"
        :to=" { name: 'insurance' }"
      >
        Resources
        <div class="drop">
          <router-link
            class="link"
            exact
            active-class="active"
            :to=" { name: 'videos' }"
          >
            Videos
          </router-link>
          <router-link
            class="link"
            exact
            active-class="active"
            :to="{ name: 'insurance' }"
          >
            Insurance
          </router-link>
          <router-link
            class="link"
            exact
            active-class="active"
            :to="{ name: 'announcements' }"
          >
            Announcements
          </router-link>
          <router-link
            class="link"
            exact
            active-class="active"
            :to="{ name: 'seeking' }"
          >
            Tips For Seeking
          </router-link>
          <router-link
            class="link"
            exact
            active-class="active"
            :to="{ name: 'posting' }"
          >
            Tips For Listing
          </router-link>
          <router-link
            class="link"
            exact
            active-class="active"
            :to="{ name: 'costcalc' }"
          >
            Cost Calculator
          </router-link>
          <router-link
            class="link"
            exact
            active-class="active"
            :to="{ name: 'earningscalc' }"
          >
            Earnings Calculator
          </router-link>
          <router-link
            class="link"
            exact
            active-class="active"
            :to="{ name: 'investors' }"
          >
            Investors
          </router-link>
          <router-link
            class="link"
            exact
            active-class="active"
            :to="{ name: 'sharing' }"
          >
            Sharing Documents
          </router-link>
        </div>
      </router-link>
      <router-link
        class="link has-drop"
        exact
        active-class="active"
        :to=" { name: 'how-it-works' }"
      >
        Company
        <div class="drop">
          <router-link
            class="link"
            exact
            active-class="active"
            :to="{ name: 'company' }"
          >
            About Us
          </router-link>
          <router-link
            class="link"
            exact
            active-class="active"
            :to="{ name: 'how-it-works' }"
          >
            How It Works
          </router-link>
          <router-link
            class="link"
            exact
            active-class="active"
            :to="{ name: 'our-team' }"
          >
            Our Team
          </router-link>
          <router-link
            class="link"
            exact
            active-class="active"
            :to="{ name: 'feedback' }"
          >
            Give Feedback
          </router-link>
          <router-link
            class="link"
            exact
            active-class="active"
            :to="{ name: 'faq' }"
          >
            FAQ's
          </router-link>
        </div>
      </router-link>
    </div>
    <div
      v-if="me"
      class="buttons"
    >
      <button
        class="avatar"
        @click.stop="toggleUserNav"
      >
        <fa-icon
          v-if="!me"
          icon="user"
        />
        <span
          v-else
          class="image avatar-image aspect-ratio-1-1"
          :style="getBackgroundStyle((me.avatar && me.avatar.url) || defaultAvatarUrl)"
        />
      </button>
      <div
        v-if="isUserNavVisible"
        v-click-outside="toggleUserNav"
        class="user-nav"
      >
        <router-link
          class="link"
          :to="{ name: 'manage-account'}"
        >
          <fa-icon
            icon="user"
            class="icon"
          /> Account
        </router-link>
        <router-link
          class="link"
          :to="{ name: 'manage-listings'}"
        >
          <fa-icon
            icon="list-alt"
            class="icon"
          /> My Planes
        </router-link>
        <router-link
          class="link"
          :to="{ name: 'favorites'}"
        >
          <fa-icon
            icon="heart"
            class="icon"
          /> Favorites
        </router-link>
        <router-link
          class="link"
          :to="{ name: 'manage-favorite-airports'}"
        >
          <fa-icon
            icon="bell"
            class="icon"
          /> Alerts
        </router-link>
        <router-link
          class="link"
          :to="{ name: 'manage-subscription'}"
        >
          <fa-icon
            icon="newspaper"
            class="icon"
          /> Subscription
        </router-link>
        <div class="logout-btn-container">
          <button
            class="btn yellow inv logout"
            @click="logout"
          >
            Log out
          </button>
        </div>
      </div>
      <!-- <a
        class="outer-logout"
        @click="logout"
      >
        Logout
      </a> -->
    </div>
    <div
      v-else
      class="buttons"
    >
      <div
        class="btn dense"
        @click="login"
      >
        Log in
      </div>
      <div
        class="btn yellow dense"
        @click="signup"
      >
        Sign Up
      </div>
    </div>
    <div
      class="nav-toggle"
      @click.stop="nav = !nav"
    >
      <span
        v-if="!nav"
      />
      <span
        v-if="!nav"
      />
      <span
        v-if="!nav"
      />
      <span
        v-if="nav"
        id="close-left"
      />
      <span
        v-if="nav"
        id="close-right"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  LOGOUT_USER,
  DELETE_USER_ACCOUNT,
  INSTRUCTOR_SIGNUP,
  SEARCH_SHARES,
  SHOW_MODAL,
  INSTRUCTOR_FOLLOWUP,
  UPDATE_HEADER_HEIGHT
} from '@/store/action-types';
import getBackgroundStyle from '@/utils/get-background-style';
import defaultAvatarUrl from '@/assets/icons/pilot.png';

export default {
  name: 'SmaHeader',

  data() {
    return {
      nav: false,
      isUserNavVisible: false,
      queryFetched: false,
      width: 0,
      isWide: true,
      defaultAvatarUrl
    };
  },

  computed: {
    ...mapGetters([
      'me',
      'activeModal'
    ]),

    query() { return this.$route.query; }
  },

  watch: {
    $route(to, from) {
      if (to.name !== from.name) {
        this.isUserNavVisible = false;
      }
    },

    async query(query) {
      if (!this.queryFetched && query) {
        this.queryFetched = true;

        if (query.share) {
          const id = parseInt(query.share);

          if (!this.activeModal || this.activeModal.shareId !== id) {
            await this.$store.dispatch(SEARCH_SHARES, {
              id,
              limit: 1,
              page: 1
            });

            this.$store.dispatch(SHOW_MODAL, {
              name: 'share',
              shareId: id
            });
          }
        }
      }
    }
  },

  mounted() {
    this.width = document.documentElement.clientWidth;

    if (!localStorage.takeover2) {
      this.$store.dispatch(SHOW_MODAL, { name: 'takeover' });
      localStorage.takeover2 = true;
    }

    window.addEventListener('resize', this.onResize);

    setTimeout(() => {
      this.isWide = this.width > 1204;
      if (this.isWide) {
        this.nav = true;
      } else {
        this.nav = false;
      }

      this.onResize();
    }, 0);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    getBackgroundStyle,

    instructorSignup() {
      this.$nextTick(() => {
        this.$store.dispatch(INSTRUCTOR_SIGNUP);
      });
    },

    login() {
      this.$store.dispatch(SHOW_MODAL, {
        name: 'login'
      });
    },

    signup() {
      this.$store.dispatch(INSTRUCTOR_FOLLOWUP, false);
      this.$store.dispatch(SHOW_MODAL, {
        name: 'signup'
      });
    },

    deleteUserAccount() {
      this.$store.dispatch(DELETE_USER_ACCOUNT);
    },

    toggleUserNav() {
      this.isUserNavVisible = !this.isUserNavVisible;
      // this.nav = false;
    },

    logout() {
      this.isUserNavVisible = false;

      this.$store.dispatch(LOGOUT_USER);

      this.$router.replace({
        name: 'home'
      });
    },

    closeNav() {
      if (!this.isWide) { this.nav = false; }
    },

    onResize() {
      this.$store.dispatch(UPDATE_HEADER_HEIGHT, getComputedStyle(this.$refs.header).height);

      const newWidth = document.documentElement.clientWidth;
      if (this.width === newWidth) { return; }
      this.width = newWidth;

      this.isWide = this.width > 1204;
      if (this.isWide) {
        this.nav = true;
      } else {
        this.nav = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  display: flex;
  align-items: center;
  background-color: $sma-white;
  padding: 0 1rem;
  z-index: 20;
  flex-wrap: wrap;
  position: sticky;
  top: 0;

  @media only screen and (min-width: $breakpoint-lg) {
    position: inherit;
  }

  @media only screen and (max-width: $breakpoint-sm) {
    padding: 0.5rem 1rem;
  }
}

.logo {
  height: 2.6rem;
  display: inherit;

  @media only screen and (max-width: $breakpoint-lg) {
    height: 2rem;
    display: none;
  }
}

.mobile-logo {
  display: inherit;
  height: 2rem;

  @media only screen and (min-width: $breakpoint-lg) {
    display: none;
  }
}

.gap {
  flex: 1;
  min-width: 1rem;
}

.links {
  display: flex;

  @media only screen and (max-width: $breakpoint-lg) {
    position: absolute;
    flex-direction: column;
    right: 0;
    top: 100%;
    background: $sma-white;
    width: 100%;
    height: 650px;
    overflow-x: auto;
  }

  .drop {
    display: none;
    position: absolute;
    top: 100%;
    left: -8px;
    flex-direction: column;
    background: $sma-white;
    z-index: 1;
    width: 230px;

    @media only screen and (max-width: $breakpoint-lg) {
      display: flex;
      width: 100%;
      position: relative;
      font-size: 0.8rem;
      top: 0;

      .link {
        font-weight: 500;
        font-stretch: normal;
      }
    }
  }

  .has-drop {
    position: relative;

    &:hover .drop {
      display: flex;
    }
  }
}

.nav-toggle {
  display: flex;
  height: 1.25rem;
  width: 1.5rem;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 3em;
  cursor: pointer;
  position: absolute;
  top: 0.5em;
  right: 2em;

  span {
    display: block;
    background-color: $sma-yellow;
    height: 0.25rem;
    width: 100%;
  }

  #close-left {
    transform: translate(0, 10px) rotate(45deg);
  }

  #close-right {
    transform: translate(0, -8px) rotate(135deg);
  }

  @media only screen and (min-width: $breakpoint-lg) {
    display: none;
  }

  @media only screen and (max-width: $breakpoint-sm) {
    top: 0.9em;
  }
}

.link {
  padding: 1rem;
  margin: 0;

  /* font-family:
    'HelveticaNeue-CondensedBold',
    'Helvetica Neue',
    sans-serif; */
  font-stretch: condensed;
  position: relative;
  color: $sma-blue;
  font-family: Raleway, 'Gotham Narrow A', 'Gotham Narrow B', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  text-transform: uppercase;

  @media only screen and (max-width: $breakpoint-lg) {
    padding: 0.5rem;
  }

  &.active {
    color: $sma-yellow;

    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 0.2rem;
      width: calc(100% - 2rem);
      background: $sma-yellow;
    }
  }
}

.links > .link {
  @media only screen and (max-width: $breakpoint-lg) {
    font-size: 18px;
    font-weight: bold;
  }
}

.buttons {
  display: flex;
  align-items: center;

  @media only screen and (max-width: $breakpoint-lg) {
    margin-right: 3em;
  }
}

.outer-logout {
  cursor: pointer;
  font-size: 0.75rem;
  padding: 0.5rem;
  color: $sma-blue;

  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
}

.avatar {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border: 0;
  border-radius: 50%;
  color: $sma-white;
  background-color: $sma-yellow;
  font-size: 0.75rem;
  margin-left: 0.5rem;
  overflow: hidden;
  padding: 0;
}

.user-nav {
  position: absolute;
  top: 100%;
  right: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  background-color: $sma-white;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
  font-size: 0.875rem;

  .link {
    display: flex;
    align-items: center;
    padding: 0.25rem;
  }

  .icon {
    margin-right: 0.5rem;
  }
}

.avatar-image {
  width: 100%;
  height: 100%;
}

.logout-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
