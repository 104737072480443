/* eslint-disable no-underscore-dangle */

import * as Resize from 'iframe-resizer/js/iframeResizer.contentWindow.min';
import Vue from 'vue';
import { ToggleButton } from 'vue-js-toggle-button';
import Slider from 'vue-concise-slider';
import createApp from './app';
import '@/gmap';
import './stripe';

Vue.use(Resize);

Vue.component('ToggleButton', ToggleButton);

Vue.component('Slider', Slider);

const { app, store } = createApp();

if (window.__INITIAL_STATE__) {
  store.replaceState(window.__INITIAL_STATE__);
}

app.$mount('#app');
