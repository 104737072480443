<template>
  <div
    ref="el"
    class="stripe-element"
  />
</template>

<script>
export default {
  name: 'SmaStripeElement',

  props: {
    type: { type: String, default: 'card' },
    elements: { type: Object, default: () => null },
    options: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      stripeElement: null
    };
  },

  watch: {
    options: {
      deep: true,
      handler(options) {
        if (this.stripeElement) { this.stripeElement.update(options); }
      }
    }
  },

  async mounted() {
    const stripe = await this.$stripe;
    const { el } = this.$refs;

    const elements = this.elements || stripe.elements();
    this.stripeElement = elements.create(this.type, this.options);

    this.stripeElement.on('change', (...args) => { this.$emit('change', ...args); });
    this.stripeElement.on('ready', (...args) => { this.$emit('ready', ...args); });
    this.stripeElement.on('focus', (...args) => { this.$emit('focus', ...args); });
    this.stripeElement.on('blur', (...args) => { this.$emit('blur', ...args); });
    this.stripeElement.on('escape', (...args) => { this.$emit('escape', ...args); });
    this.stripeElement.on('click', (...args) => { this.$emit('click', ...args); });

    this.stripeElement.mount(el);
  },

  beforeDestroy() {
    if (this.stripeElement) { this.stripeElement.destroy(); }
  },

  methods: {
    blur() {
      if (this.stripeElement) { this.stripeElement.blur(); }
    },

    clear() {
      if (this.stripeElement) { this.stripeElement.clear(); }
    },

    focus() {
      if (this.stripeElement) { this.stripeElement.focus(); }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
