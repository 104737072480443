<template>
  <div
    v-if="activeModalName === 'subscribe'"
    class="subscribe-modal modal-background"
    @click="hide"
  >
    <div
      class="modal"
      @click.stop
    >
      <button
        class="close-btn"
        @click="hide"
      >
        <fa-icon icon="times" />
      </button>

      <div class="starburst-container">
        <div class="starburst">
          <img
            class="bg"
            src="@/assets/starburst.svg"
          >
          <div
            class="text"
            :class="[subType]"
          >
            <div class="h1">
              SMA
            </div>
            <div
              v-if="subTitle"
              class="h2"
            >
              {{ subTitle }}
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="h1">
          Sign-Up Now!
        </div>
        <div class="h2">
          {{ subCallout }}
        </div>

        <div class="body">
          {{ subDesc }}
        </div>

        <div class="ccs">
          <img
            class="cc"
            src="@/assets/cc-mastercard.png"
          >
          <img
            class="cc"
            src="@/assets/cc-visa.png"
          >
          <img
            class="cc"
            src="@/assets/cc-americanexpress.png"
          >
          <img
            class="cc"
            src="@/assets/cc-discover.png"
          >
        </div>

        <div class="form">
          <div class="row">
            <div class="col">
              <input
                v-model="form.fullName"
                placeholder="Full Name"
                class="input"
              >
            </div>
          </div>

          <div class="row">
            <div class="col">
              <sma-stripe-element
                ref="card"
                type="cardNumber"
                :options="{
                  placeholder: 'Card Number',
                  style: stripeInputStyle
                }"
                :elements="elements"
                class="input"
                @change="onCardChange"
              />
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-12">
              <sma-stripe-element
                type="cardExpiry"
                :options="{ style: stripeInputStyle }"
                :elements="elements"
                class="input"
                @change="onCardChange"
              />
            </div>

            <div class="col col-sm-12">
              <sma-stripe-element
                type="cardCvc"
                :options="{ style: stripeInputStyle }"
                :elements="elements"
                class="input"
                @change="onCardChange"
              />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <input
                v-model="form.promoCode"
                placeholder="Promotional Code (Optional)"
                class="input"
              >
            </div>
          </div>
        </div>

        <div class="error-msg">
          {{ errMsg }}
        </div>

        <div class="footnote">
          When you sign-up, you authorize ShareMyAircraft.com to charge your payment method at the above rate and automatically renew monthly at the same rate. You may cancel at any time by going to your profile and selecting Subscriptions &lt; Cancel.
        </div>

        <div class="subscribe-container">
          <button
            class="btn yellow"
            @click="onSubClick"
          >
            <span v-if="!isSubscribing">SUBSCRIBE NOW</span>
            <fa-icon
              v-else
              icon="circle-notch"
              spin
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SmaStripeElement from '@/components/StripeElement';

import {
  HIDE_MODAL,
  CREATE_CUSTOMER,
  CREATE_SUBSCRIPTION,
  SHOW_MODAL
} from '@/store/action-types';

export default {
  name: 'SmaSubscribeModal',

  components: {
    SmaStripeElement
  },

  data() {
    return {
      form: {
        fullName: '',
        promoCode: ''
      },
      errMsg: '',
      stripeInputStyle: {
        base: {
          'color': '#757373',
          'fontFamily': 'Gotham Narrow A, Gotham Narrow B, Helvetica Neue, Helvetica, Arial, sans-serif',
          'fontWeight': 400,
          'fontSize': '15.75px',
          'textShadow': 'none',
          '::placeholder': {
            color: '#bbb',
            fontWeight: 400
          }
        }
      },
      elements: null,
      isSubscribing: false
    };
  },

  computed: {
    ...mapGetters([
      'me',
      'activeModal',
      'activeModalName'
    ]),

    subType() {
      return (this.$store.getters.activeModal || {}).subType;
    },

    subTitle() {
      switch (this.subType) {
        case 'list':
          return 'LISTINGS';

        case 'instructor':
          return '';

        default:
          return '';
      }
    },

    subDesc() {
      switch (this.subType) {
        case 'list':
          return 'Just $19 per month to post unlimited shares. Cancel at any time.';
        case 'instructor':
          return 'First month is free!  Post your profile today.';
        default:
          return '';
      }
    },

    subCallout() {
      switch (this.subType) {
        case 'list':
          return 'Then post your first share in minutes, from your computer or smart phone!';
        case 'instructor':
          return 'List your instructor profile for just $19 per month';
        default:
          return '';
      }
    },

    priceId() {
      switch (this.subType) {
        case 'list':
          return process.env.LIST_SUBSCRIPTION_PRICE_ID;
        case 'instructor':
          return process.env.INSTRUCTOR_SUBSCRIPTION_PRICE_ID;
        default:
          return '';
      }
    }
  },

  watch: {
    me(me) {
      this.form.fullName = `${me.firstname} ${me.lastname}`;
    }
  },

  async mounted() {
    const stripe = await this.$stripe;
    this.elements = stripe.elements();
  },

  methods: {
    hide() {
      this.$store.dispatch(HIDE_MODAL);
      this.form.errMsg = '';
    },

    displayError(err) {
      if (err) {
        this.errMsg = err.message;
      } else {
        this.errMsg = '';
      }
    },

    async createSubscription() {
      const stripe = await this.$stripe;
      const card = this.$refs.card.stripeElement;

      const { error, paymentMethod } = await stripe
        .createPaymentMethod({
          type: 'card',
          card,
          billing_details: {
            name: this.form.fullName
          }
        });

      if (error) { throw error; }

      if (!this.me.customer) {
        await this.$store.dispatch(CREATE_CUSTOMER);
      }

      await this.$store.dispatch(CREATE_SUBSCRIPTION, {
        paymentMethodId: paymentMethod.id,
        priceId: this.priceId,
        promoCode: this.form.promoCode
      });
    },

    /*
     * Handlers
     */

    onCardChange(e) {
      this.displayError(e.error);
    },

    async onSubClick() {
      if (this.isSubscribing) { return; }
      this.isSubscribing = true;

      try {
        await this.createSubscription();
      } catch (err) {
        if (err.response?.status === 400 && err.response?.data === 'InvalidPromoCode') {
          this.displayError({ message: 'Invalid Promotional Code' });
          return;
        }

        console.error(err);
        this.displayError(err);
        return;
      } finally {
        this.isSubscribing = false;
      }

      if (this.subType === 'instructor') {
        setTimeout(() => {
          this.$store.dispatch(SHOW_MODAL, { name: 'instructor-signup' });
        }, 100);
      }

      this.hide();
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  width: 500px;
  overflow: visible !important;
  margin-top: 75px !important;
}

.starburst-container {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
}

.starburst {
  position: absolute;
  top: -75px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;

  .bg {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  .text {
    z-index: 2;
    text-align: center;
    color: $sma-white;
    font-weight: 500;
    border: 1px dashed $sma-white;
    border-radius: 999px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    height: 80%;

    .h1 {
      font-size: 14px;
    }

    .h2 {
      font-size: 20px;
    }

    &.instructor {
      .h1 {
        font-size: 28px;
        font-weight: 700;
      }
    }
  }
}

.content {
  padding-top: 75px !important;
  text-align: center;

  .input {
    height: 38px;
    background: $sma-white;
    border: 1px solid $sma-light-gray;
    border-radius: 5px;
    padding: 8px;
  }

  .h1,
  .h2 {
    color: $sma-blue;
    font-weight: 600;
  }

  .h1 {
    font-size: 24px;
  }

  .h2 {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  .body {
    margin-bottom: 1rem;
  }

  .ccs {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    .cc {
      width: 42px;

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  .error-msg {
    margin: 0.5rem 0;
    font-size: 0.875rem;
    color: $sma-red;
  }

  .footnote {
    font-size: 12px;
    margin-bottom: 1rem;
  }
}

.button {
  display: block;
  cursor: pointer;
  padding: 0.5rem 4rem;
  margin: 0.5rem auto;
  border: 1px solid $sma-yellow;
  color: $sma-yellow;
  border-radius: 10rem;
  white-space: nowrap;
  text-align: center;
}
</style>
