import Vue from 'vue';

import {
  adminDeleteUserAccount,
  createUserAccount,
  createCustomer,
  createSubscription,
  deleteUserAccount,
  fetchUserCount,
  fetchUserProfile,
  login,
  logout,
  resetPassword,
  updateSubscription,
  updateUserProfile
} from '@/services/api';
import sleep from '@/utils/sleep';
import {
  ADMIN_DELETE_USER_ACCOUNT,
  CREATE_USER,
  CREATE_CUSTOMER,
  CREATE_SUBSCRIPTION,
  DELETE_USER_ACCOUNT,
  FETCH_USER_COUNT,
  FETCH_USER_PROFILE,
  LOGIN_WITH_CREDENTIALS,
  LOGOUT_USER,
  RESET_PASSWORD,
  UPDATE_SUBSCRIPTION,
  UPDATE_USER_PROFILE
} from '../action-types';
import {
  CREDENTIALS_INVALID,
  // MODAL_HIDDEN,
  ERRORS_CLEARED,
  USER_ACCOUNT_DELETED,
  USERS_ADDED,
  USER_COUNT_FETCHED,
  USER_CREATED,
  USER_LOGGED_IN_WITH_CREDENTIALS,
  USER_LOGGED_OUT,
  USER_PROFILE_FETCHED,
  USER_PROFILE_UPDATED
} from '../mutation-types';

const PRICE_IDS = [
  process.env.LIST_SUBSCRIPTION_PRICE_ID,
  process.env.INSTRUCTOR_SUBSCRIPTION_PRICE_ID
];

export default function createAccountPreferencesModule() {
  return {
    state: () => ({
      me: null,
      records: {},
      count: 0
    }),

    getters: {
      me: ({ me, records }) => {
        if (!me) { return null; }

        const user = {
          ...records[me],
          subscriptions: {}
        };

        PRICE_IDS.forEach((priceId) => {
          const subData = {
            id: null,
            isActive: false,
            isCanceled: false,
            isTrial: false,
            expDate: null,
            trialExpDate: null,
            discount: null
          };

          const sub = records[me].customer
            && records[me].customer.subscriptions
            && records[me].customer.subscriptions.find(s => s.priceId === priceId);

          if (sub) {
            subData.id = sub.id;
            subData.isActive = sub.currentPeriodEnd && new Date(sub.currentPeriodEnd) > new Date();
            subData.isCanceled = sub.cancelAtPeriodEnd;
            subData.isTrial = sub.trialEnd && new Date(sub.trialEnd) > new Date();
            subData.expDate = sub.currentPeriodEnd && new Intl.DateTimeFormat('en-US', {}).format(new Date(sub.currentPeriodEnd));
            subData.trialExpDate = sub.trialEnd && new Intl.DateTimeFormat('en-US', {}).format(new Date(sub.trialEnd));
            subData.discount = sub.discount?.name;
          }

          user.subscriptions[priceId] = subData;
        });

        return user;
      },
      getUserById: state => id => state.records[id],
      userCount: state => state.count
    },

    mutations: {
      [USERS_ADDED]: (state, users) => {
        users.forEach(user => Vue.set(state.records, user.id, user));
      },

      [USER_CREATED]: (state, user) => {
        state.me = user.id;
        // State.records[user.id] = user;
        Vue.set(state.records, user.id, user); // Using Vue.set for better reactivity
      },

      [USER_ACCOUNT_DELETED]: (state) => {
        state.me = null;
      },

      [USER_LOGGED_IN_WITH_CREDENTIALS]: (state, user) => {
        state.me = user.id;
        Vue.set(state.records, user.id, user); // Using Vue.set for better reactivity
      },

      [USER_LOGGED_OUT]: (state) => {
        state.me = null;
      },

      [USER_COUNT_FETCHED]: (state, count) => {
        state.count = count;
      },

      [USER_PROFILE_FETCHED]: (state, user) => {
        state.me = user.id;
        Vue.set(state.records, user.id, user); // Using Vue.set for better reactivity
      },

      [USER_PROFILE_UPDATED]: (state, user) => {
        Vue.set(state.records, user.id, user); // Using Vue.set for better reactivity
      }
    },

    actions: {
      [CREATE_USER]: async (context, options) => {
        const user = await createUserAccount(options);
        await sleep(1000);
        context.commit(USER_CREATED, user);
        // context.commit(MODAL_HIDDEN);
      },

      [CREATE_CUSTOMER]: async (context) => {
        await createCustomer();
        await context.dispatch(FETCH_USER_PROFILE);
      },

      [CREATE_SUBSCRIPTION]: async (context, { priceId, paymentMethodId, promoCode }) => {
        await createSubscription({ priceId, paymentMethodId, promoCode });
        await context.dispatch(FETCH_USER_PROFILE);
      },

      [RESET_PASSWORD]: async (context, options) => {
        await resetPassword(options);
      },

      [DELETE_USER_ACCOUNT]: async (context) => {
        context.commit(USER_ACCOUNT_DELETED, await deleteUserAccount());
      },

      [ADMIN_DELETE_USER_ACCOUNT]: async (context, id) => {
        await adminDeleteUserAccount(id);
      },

      [LOGIN_WITH_CREDENTIALS]: async (context, options) => {
        try {
          const user = await login(options);
          context.commit(USER_LOGGED_IN_WITH_CREDENTIALS, user);
          context.commit(ERRORS_CLEARED);
        } catch (err) {
          if (err.response && err.response.status === 400) {
            context.commit(CREDENTIALS_INVALID);
          }
        }
      },

      [LOGOUT_USER]: async (context) => {
        await logout();
        context.commit(USER_LOGGED_OUT);
      },

      [FETCH_USER_COUNT]: async (context) => {
        const count = await fetchUserCount();
        context.commit(USER_COUNT_FETCHED, count);
      },

      [FETCH_USER_PROFILE]: async (context, options) => {
        if (context.state.me && options && !options.force) {
          return;
        }

        const user = await fetchUserProfile();

        if (user) {
          context.commit(USER_PROFILE_FETCHED, user);
        }
      },

      [UPDATE_SUBSCRIPTION]: async (context, { subscriptionId, cancelAtPeriodEnd }) => {
        await updateSubscription(subscriptionId, { cancelAtPeriodEnd });
        await context.dispatch(FETCH_USER_PROFILE);
      },

      [UPDATE_USER_PROFILE]: async (context, options) => {
        const user = await updateUserProfile(options);
        await sleep(1000);
        context.commit(USER_PROFILE_UPDATED, user);

        await context.dispatch(FETCH_USER_PROFILE);
      }
    }
  };
}

export const state = {
  me: null,
  records: {},
  count: 0
};

export const getters = {
  me: ({ me, records }) => {
    if (!me) { return null; }

    const user = {
      ...records[me],
      subscriptions: {}
    };

    PRICE_IDS.forEach((priceId) => {
      const subData = {
        id: null,
        isActive: false,
        isCanceled: false,
        isTrial: false,
        expDate: null,
        trialExpDate: null
      };

      const sub = records[me].customer
        && records[me].customer.subscriptions
        && records[me].customer.subscriptions.find(s => s.priceId === priceId);

      if (sub) {
        subData.id = sub.id;
        subData.isActive = sub.currentPeriodEnd && new Date(sub.currentPeriodEnd) > new Date();
        subData.isCanceled = sub.cancelAtPeriodEnd;
        subData.isTrial = sub.trialEnd && new Date(sub.trialEnd) > new Date();
        subData.expDate = sub.currentPeriodEnd && new Intl.DateTimeFormat('en-US', {}).format(new Date(sub.currentPeriodEnd));
        subData.trialExpDate = sub.trialEnd && new Intl.DateTimeFormat('en-US', {}).format(new Date(sub.trialEnd));
      }

      user.subscriptions[priceId] = subData;
    });

    return user;
  },
  getUserById: state => id => state.records[id],
  userCount: state => state.count
};

export const mutations = {
  [USERS_ADDED]: (state, users) => {
    users.forEach(user => Vue.set(state.records, user.id, user));
  },

  [USER_CREATED]: (state, user) => {
    state.me = user.id;
    // State.records[user.id] = user;
    Vue.set(state.records, user.id, user); // Using Vue.set for better reactivity
  },

  [USER_ACCOUNT_DELETED]: (state) => {
    state.me = null;
  },

  [USER_LOGGED_IN_WITH_CREDENTIALS]: (state, user) => {
    state.me = user.id;
    Vue.set(state.records, user.id, user); // Using Vue.set for better reactivity
  },

  [USER_LOGGED_OUT]: (state) => {
    state.me = null;
  },

  [USER_COUNT_FETCHED]: (state, count) => {
    state.count = count;
  },

  [USER_PROFILE_FETCHED]: (state, user) => {
    state.me = user.id;
    Vue.set(state.records, user.id, user); // Using Vue.set for better reactivity
  },

  [USER_PROFILE_UPDATED]: (state, user) => {
    Vue.set(state.records, user.id, user); // Using Vue.set for better reactivity
  }
};

export const actions = {
  [CREATE_USER]: async (context, options) => {
    const user = await createUserAccount(options);
    await sleep(1000);
    context.commit(USER_CREATED, user);
    // context.commit(MODAL_HIDDEN);
  },

  [CREATE_CUSTOMER]: async (context) => {
    await createCustomer();
    await context.dispatch(FETCH_USER_PROFILE);
  },

  [CREATE_SUBSCRIPTION]: async (context, { priceId, paymentMethodId, promoCode }) => {
    await createSubscription({ priceId, paymentMethodId, promoCode });
    await context.dispatch(FETCH_USER_PROFILE);
  },

  [RESET_PASSWORD]: async (context, options) => {
    await resetPassword(options);
  },

  [DELETE_USER_ACCOUNT]: async (context) => {
    context.commit(USER_ACCOUNT_DELETED, await deleteUserAccount());
  },

  [ADMIN_DELETE_USER_ACCOUNT]: async (context, id) => {
    await adminDeleteUserAccount(id);
  },

  [LOGIN_WITH_CREDENTIALS]: async (context, options) => {
    try {
      const user = await login(options);
      context.commit(USER_LOGGED_IN_WITH_CREDENTIALS, user);
      context.commit(ERRORS_CLEARED);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        context.commit(CREDENTIALS_INVALID);
      }
    }
  },

  [LOGOUT_USER]: async (context) => {
    await logout();
    context.commit(USER_LOGGED_OUT);
  },

  [FETCH_USER_COUNT]: async (context) => {
    const count = await fetchUserCount();
    context.commit(USER_COUNT_FETCHED, count);
  },

  [FETCH_USER_PROFILE]: async (context, options) => {
    if (context.state.me && options && !options.force) {
      return;
    }

    const user = await fetchUserProfile();

    if (user) {
      context.commit(USER_PROFILE_FETCHED, user);
    }
  },

  [UPDATE_SUBSCRIPTION]: async (context, { subscriptionId, cancelAtPeriodEnd }) => {
    await updateSubscription(subscriptionId, { cancelAtPeriodEnd });
    await context.dispatch(FETCH_USER_PROFILE);
  },

  [UPDATE_USER_PROFILE]: async (context, options) => {
    const user = await updateUserProfile(options);
    await sleep(1000);
    context.commit(USER_PROFILE_UPDATED, user);

    await context.dispatch(FETCH_USER_PROFILE);
  }
};
