<template>
  <div
    v-if="$route.name === 'landing-frame' || $route.name === 'map-frame'"
    id="app"
  >
    <router-view />
  </div>
  <div
    v-else
    id="app"
  >
    <sma-header
      ref="header"
      class="header"
    />
    <router-view class="page" />
    <sma-footer class="footer" />
    <sma-forgot-password-modal />
    <sma-instructor-modal />
    <sma-share-modal />
    <sma-login-modal />
    <sma-instructor-signup-modal />
    <sma-signup-modal />
    <sma-feedback-modal />
    <sma-video-modal />
    <sma-takeover />
    <sma-subscribe-modal />
    <sma-subscribe-notification-modal />
    <sma-user-modal />
  </div>
</template>

<script>
import SmaFooter from '@/components/Footer';
import SmaForgotPasswordModal from '@/components/ForgotPasswordModal';
import SmaHeader from '@/components/Header';
import SmaInstructorModal from '@/components/InstructorModal';
import SmaShareModal from '@/components/ShareModal';
import SmaLoginModal from '@/components/LoginModal';
import SmaSignupModal from '@/components/SignupModal';
import SmaInstructorSignupModal from '@/components/InstructorSignupModal';
import SmaFeedbackModal from '@/components/FeedbackModal';
import SmaVideoModal from '@/components/VideoModal';
import SmaSubscribeModal from '@/components/SubscribeModal';
import SmaSubscribeNotificationModal from '@/components/SubscribeNotificationModal';
import SmaTakeover from '@/components/Takeover';
import SmaUserModal from '@/components/UserModal';

export default {
  name: 'App',
  components: {
    SmaFooter,
    SmaForgotPasswordModal,
    SmaHeader,
    SmaInstructorModal,
    SmaShareModal,
    SmaLoginModal,
    SmaSignupModal,
    SmaInstructorSignupModal,
    SmaFeedbackModal,
    SmaVideoModal,
    SmaSubscribeModal,
    SmaSubscribeNotificationModal,
    SmaTakeover,
    SmaUserModal
  },

  mounted() {
    this.$router.beforeEach((to, from, next) => {
      const { header } = this.$refs;
      if (header) {
        header.closeNav();
      }

      next();
    });
  },

  metaInfo() {
    return {
      title: 'ShareMyAircraft'
    };
  }
};
</script>

<style lang="scss">
@import './css/styles.scss';

#app {
  height: 100%;
  position: relative;

  .header {
    z-index: 10;
  }

  .page {
    z-index: 5;
  }

  .footer {
    z-index: 1;
  }
}
</style>
